const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import('../components/home/home.vue')
    },
    {
        name: 'appManual',
        path: '/appManual',
        component: () => import('../components/appManual/appManual.vue')
    },
    {
        name: 'detail01',
        path: '/detail01',
        component: () => import('../components/appManual/detail01.vue')
    },
    {
        name: 'detail02',
        path: '/detail02',
        component: () => import('../components/appManual/detail02.vue')
    },
    {
        name: 'detail03',
        path: '/detail03',
        component: () => import('../components/appManual/detail03.vue')
    },
    {
        name: 'detail04',
        path: '/detail04',
        component: () => import('../components/appManual/detail04.vue')
    },
    {
        name: 'detail05',
        path: '/detail05',
        component: () => import('../components/appManual/detail05.vue')
    },
    {
        name: 'detail06',
        path: '/detail06',
        component: () => import('../components/appManual/detail06.vue')
    },
    {
        name: 'detail07_01',
        path: '/detail07_01',
        component: () => import('../components/appManual/detail07_01.vue')
    },
    {
        name: 'detail07_02',
        path: '/detail07_02',
        component: () => import('../components/appManual/detail07_02.vue')
    },
    {
        name: 'detail08',
        path: '/detail08',
        component: () => import('../components/appManual/detail08.vue')
    },
    {
        name: 'promoDetail',
        path: '/promoDetail',
        component: () => import('../components/promoDetail/promoDetail.vue')
    },
    {
        name: 'roulette',
        path: '/roulette',
        component: () => import('../components/roulette/Roulette')
    }
]

export default routes
