import Vue from 'vue'
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter)

// 跳转重复路由导致的报错屏蔽
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
    routes,
    mode: 'hash',
    base: '/',
    fallback: true
})

export default router
