import './reSetWidthHeight.less';
import './initPxToRem';
import Vue from 'vue'
import 'vant/lib/index.css';
import Vant from 'vant';
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(Vant)

new Vue({
  render: h => h(App),
}).$mount('#app')
