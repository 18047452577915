<template>
  <div id="app">
    <div id='pop_des' style="display: none" class="center_status_pop">pop_des</div>
    <router-view/>
  </div>
</template>

<script>
import router from "./router";

export default {
  name: 'App',
  router,
  components: {

  }
}
</script>

<style lang="less">
  @px: 100rem;

  .center_status_pop {
    width: auto!important;
    height: auto!important;
    padding-left: 20/@px;
    padding-right: 20/@px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 50/@px;
    border-radius: 10/@px;
    font-size: 16/@px;
    font-weight: 400;
    color: white;
    background: rgba(0, 0, 0, .8);

    z-index: 999;
  }
</style>
